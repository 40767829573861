import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import fetchData from "../services/requester";

const ForgotPassword = (props) => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");

  const handleSubmitEmail = (values) => {
    let reqURL = "forgot-password";
    let reqOBJ = values;
    setEmail(values?.email);

    fetchData(reqURL, reqOBJ, (data) => {
      if (data?.success) {
        message.success("OTP sent to your email.");
        setStep(2);
      } else {
        message.error(data || "Something went wrong");
      }
    });
  };

  const handleSubmitOtp = (values) => {
    let reqURL = "verify-otp";
    let reqOBJ = values;
    reqOBJ.email = email;

    fetchData(reqURL, reqOBJ, (data) => {
      if (data?.success) {
        setStep(3);
      } else {
        message.error(data || "Something went wrong");
      }
    });
  };

  const handleResetPassword = (values) => {
    let reqURL = "reset-password";
    let reqOBJ = values;
    reqOBJ.email = email;

    fetchData(reqURL, reqOBJ, (data) => {
      if (data?.success) {
        message.success("Password updated successfully.");
      } else {
        message.error(data || "Something went wrong");
      }
      props.handleReset();
    });
  };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  return (
    <div>
      {step === 1 && (
        <Form onFinish={handleSubmitEmail} {...layout}>
          <Form.Item label="Email Id" name="email" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8 }}>
            <Button type="primary" htmlType="submit">
              Send OTP
            </Button>
          </Form.Item>
        </Form>
      )}

      {step === 2 && (
        <Form onFinish={handleSubmitOtp} {...layout}>
          <Form.Item label="OTP" name="otp" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8 }}>
            <Button type="primary" htmlType="submit">
              Verify OTP
            </Button>
          </Form.Item>
        </Form>
      )}

      {step === 3 && (
        <Form onFinish={handleResetPassword} {...layout}>
          <Form.Item
            label="New Password"
            name="password"
            rules={[{ required: true }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8 }}>
            <Button type="primary" htmlType="submit">
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default ForgotPassword;
