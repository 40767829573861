import React, { useState } from "react";
import config from "../config.json";
import { Form, Input, Button, Typography, message, Drawer } from "antd";
import { useNavigate } from "react-router-dom";
import fetchData from "../services/requester";
import ForgotPassword from "../components/forgot-password";

const SignIn = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { Title } = Typography;
  const host = window.location.hostname;
  const isCustomerHost = host === "customer.travelebrium.com";
  const isSupplierHost = host === "supplier.travelebrium.com";
  const isAdminHost = host === "admin.travelebrium.com";
  const isDevelopment =
    config?.mode === "dev" || config?.mode === "staging" ? true : false;
  const [visible, setVisible] = useState(false);

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const onFinish = (values) => {
    setLoading(true);
    let reqURL = "signin";
    let reqOBJ = values;

    fetchData(reqURL, reqOBJ, (data) => {
      setLoading(false);

      if (data?.token && data?.role) {
        let isCustomer = isCustomerHost && data?.role === "Customer";
        let isSupplier = isSupplierHost && data?.role === "Supplier";
        let isAdmin = isAdminHost && data?.role === "Admin";

        if (isDevelopment || isCustomer || isSupplier || isAdmin) {
          localStorage.setItem("token", data?.token);
          localStorage.setItem("role", data?.role);
          localStorage.setItem("name", data?.name);
          navigate("/");
        } else {
          message.error("Unauthenticated user.");
        }
      } else {
        message.error(data?.message || "Email Id or Password Incorrect.");
      }
    });
  };

  const handleSignup = () => {
    navigate("/signup");
  };

  return (
    <div className="signin-box">
      <Title level={4} className="signin-box-title">
        Sign in
      </Title>

      <Form
        name="signin"
        autoComplete="off"
        onFinish={onFinish}
        className="signin-form"
        {...layout}
      >
        <Form.Item
          label="Email Id"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input Email Id!",
            },
          ]}
        >
          <Input placeholder="Email Id" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="Password"
          rules={[
            {
              required: true,
              message: "Please input Password!",
            },
          ]}
        >
          <Input.Password type="password" placeholder="Password" />
        </Form.Item>

        <Form.Item
          wrapperCol={{ xs: { offset: 0 }, xl: { offset: 8 } }}
          className="btn-row"
        >
          <Button type="primary" htmlType="submit" loading={loading}>
            Sign in
          </Button>
          {(isCustomerHost || isDevelopment) && (
            <Button type="default" onClick={handleSignup}>
              Sign up
            </Button>
          )}
          <div className="mt-3">
            <Button
              type="link"
              className="reset-icon-btn"
              onClick={() => setVisible(true)}
            >
              Forgot / Reset Password?
            </Button>
          </div>
        </Form.Item>
      </Form>

      <Drawer
        title="Forgot password"
        placement="right"
        onClose={() => setVisible(false)}
        open={visible}
      >
        <ForgotPassword key={visible} handleReset={() => setVisible(false)} />
      </Drawer>
    </div>
  );
};

export default SignIn;
