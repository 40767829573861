import React, { useState, useEffect } from "react";
import fetchData from "../services/requester";
import { Form, Input, Button, Select, message, InputNumber } from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";

const SupplierAdd = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isEditMode, setEditMode] = useState(false);

  const layout = {
    labelCol: {
      span: 7,
    },
    wrapperCol: {
      span: 14,
    },
  };

  const onFinish = (values) => {
    const { supplierId } = props;

    setLoading(true);
    let reqURL = "suppliers/" + (supplierId ? "update" : "add");
    let reqOBJ = values;

    if (supplierId) {
      reqOBJ.supplierId = supplierId;
    }

    fetchData(reqURL, reqOBJ, (data) => {
      setLoading(false);

      if (data?.success) {
        message.success("Supplier added successfully.");
        props.handleAddSupplier();
      } else {
        message.error("Oops! Something went wrong.");
      }
    });
  };

  const setDefaultData = () => {
    form.setFieldsValue({
      supplierName: props?.supplierName || "",
      supplierEmail: props?.supplierEmail || "",
      supplierPhone: props?.supplierPhone || "",
      supplierMarkup: props?.supplierMarkup || "",
    });
  };

  useEffect(() => {
    props?.supplierId && setEditMode(true);
    props?.supplierId && setDefaultData();
  }, []);

  return (
    <React.Fragment>
      <Form
        name="supplier-add"
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        className="supplier-add"
        {...layout}
        initialValues={{
          countryCode: "+91",
        }}
      >
        <Form.Item
          label="Supplier Name"
          name="supplierName"
          rules={[
            {
              required: true,
              message: "Please input Supplier Name!",
            },
          ]}
        >
          <Input placeholder="First and Last Name" />
        </Form.Item>

        <Form.Item
          label="Email Id"
          name="supplierEmail"
          rules={[
            {
              type: "email",
              required: true,
              message: "Please input Email Id!",
            },
          ]}
        >
          <Input placeholder="Enter Email Id" disabled={isEditMode} />
        </Form.Item>

        <Form.Item
          label="Phone Number"
          name="supplierPhone"
          rules={[
            {
              required: true,
              message: "Please input Phone Number!",
            },
          ]}
        >
          <Input
            addonBefore={
              <Form.Item name="countryCode" noStyle>
                <Select style={{ width: 70 }}>
                  <Select.Option value="+91">+91</Select.Option>
                </Select>
              </Form.Item>
            }
            placeholder="Enter Phone Number"
            disabled={isEditMode}
          />
        </Form.Item>

        {!isEditMode && (
          <Form.Item
            label="Create Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input Password!",
              },
            ]}
          >
            <Input type="password" placeholder="Enter Password" />
          </Form.Item>
        )}

        <Form.Item
          label="Markup"
          name="supplierMarkup"
          rules={[
            {
              required: true,
              message: "Please input Markup!",
            },
          ]}
        >
          <InputNumber
            min={0}
            placeholder="Enter Markup in %"
            className="w-100"
          />
        </Form.Item>

        <Form.Item
          wrapperCol={{ xs: { offset: 0 }, xl: { offset: 7 } }}
          className="btn-row"
        >
          <Button
            icon={props?.supplierId ? <EditOutlined /> : <PlusOutlined />}
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            {props?.supplierId ? "Update" : "Add"} Supplier
          </Button>
        </Form.Item>
      </Form>
    </React.Fragment>
  );
};

export default SupplierAdd;
