import React, { useState, useEffect } from "react";
import fetchData from "../services/requester";
import dayjs from "dayjs";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  message,
  InputNumber,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { vehicleTypeList } from "./lookups";
import { ResDate } from "../components/formatter";

const AddRequest = (props) => {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [statewiseCityList, setStatewiseCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const handleValuesChange = (value, values) => {
    if (value["state"]) {
      let filteredCities = statewiseCityList
        ?.find((x) => x?.state === value["state"])
        ?.cities.map((x) => ({
          value: x,
          name: x,
        }));

      setCityList(filteredCities);

      form.setFieldsValue({
        city: "",
      });
    }
  };

  const onFinish = (values) => {
    setLoading(true);
    let reqURL = "requests/add";
    let reqOBJ = values;

    reqOBJ.country = "India";
    reqOBJ.status = "New";
    reqOBJ.pickupDateTime = ResDate(values?.pickupDateTime);

    fetchData(reqURL, reqOBJ, (data) => {
      setLoading(false);

      if (data?.success) {
        message.success("Your Request added successfully.");
        props.handleAdd();
      } else {
        message.error("Oops! Something went wrong.");
      }
    });
  };

  const disabledDate = (current) => {
    return current && current < dayjs().startOf("day");
  };

  const getData = () => {
    let reqURL = "suppliers/states-wise-cities";
    let reqOBJ = "";
    fetchData(
      reqURL,
      reqOBJ,
      (data) => {
        setStatewiseCityList(data || []);
        setStateList(
          data?.map((x) => ({ value: x?.state, name: x?.state })) || []
        );
      },
      "GET"
    );
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <React.Fragment>
      <Form
        name="request-add"
        form={form}
        {...layout}
        onFinish={onFinish}
        autoComplete="off"
        onValuesChange={handleValuesChange}
        initialValues={
          {
            // state: "Gujarat",
            // city: "Ahmedabad",
            // pickupFrom: "Internation Airport T2",
            // dropTo: "Crown Plaza, Sg Road",
            // pickupDateTime: dayjs().add(2, "d"),
            // noOfTravellers: 1,
            // noOfBags: 2,
            // vehicleType: "Sedan",
            // distance: 12,
            // bookingId: "12345",
          }
        }
      >
        <Form.Item
          label="Pickup Date & Time"
          name="pickupDateTime"
          rules={[
            {
              required: true,
              message: "Please select Pickup Date & Time!",
            },
          ]}
        >
          <DatePicker
            format={"DD/MM/YYYY HH:mm"}
            showTime={{ defaultValue: dayjs("00:00", "HH:mm") }}
            allowClear={false}
            disabledDate={disabledDate}
            style={{ minWidth: "50%" }}
          />
        </Form.Item>

        <Form.Item
          label="State"
          name="state"
          rules={[
            {
              required: true,
              message: "Please select State!",
            },
          ]}
        >
          <Select
            placeholder="Select State"
            options={stateList}
            showSearch
          ></Select>
        </Form.Item>

        <Form.Item
          label="City"
          name="city"
          // rules={[
          //   {
          //     required: true,
          //     message: "Please select City!",
          //   },
          // ]}
        >
          <Select
            placeholder="Select City"
            options={cityList}
            showSearch
            allowClear
          ></Select>
        </Form.Item>

        <Form.Item
          label="Pickup From"
          name="pickupFrom"
          rules={[
            {
              required: true,
              message: "Please input Pickup From!",
            },
          ]}
        >
          <Input placeholder="Pickup From" />
        </Form.Item>

        <Form.Item
          label="Drop To"
          name="dropTo"
          rules={[
            {
              required: true,
              message: "Please input Drop To!",
            },
          ]}
        >
          <Input placeholder="Drop To" />
        </Form.Item>

        <Form.Item
          label="No of Travellers"
          name="noOfTravellers"
          rules={[
            {
              required: true,
              message: "Please input No of Travellers!",
            },
          ]}
        >
          <InputNumber
            min={1}
            placeholder="No of Travellers"
            style={{ minWidth: "50%" }}
          />
        </Form.Item>

        <Form.Item
          label="No of Bags"
          name="noOfBags"
          rules={[
            {
              required: true,
              message: "Please input No of Bags!",
            },
          ]}
        >
          <InputNumber
            min={1}
            placeholder="No of bags"
            style={{ minWidth: "50%" }}
          />
        </Form.Item>

        <Form.Item
          label="Vehicle Type"
          name="vehicleType"
          rules={[
            {
              required: true,
              message: "Please select Vehicle Type!",
            },
          ]}
        >
          <Select
            placeholder="Select Vehicle Type"
            options={vehicleTypeList}
            showSearch
            style={{ maxWidth: "50%" }}
          ></Select>
        </Form.Item>

        <Form.Item
          label="Distance"
          name="distance"
          rules={[
            {
              required: true,
              message: "Please input Distance in KM!",
            },
          ]}
        >
          <InputNumber
            min={1}
            placeholder="Distance in KM"
            style={{ minWidth: "50%" }}
          />
        </Form.Item>

        <Form.Item
          label="LR Booking Id"
          name="bookingId"
          rules={[
            {
              required: true,
              message: "Please input LR Booking Id!",
            },
          ]}
        >
          <Input placeholder="LR Booking Id" style={{ maxWidth: "50%" }} />
        </Form.Item>

        <Form.Item label="Flight Details" name="flightDetails">
          <Input placeholder="Flight Details" style={{ maxWidth: "50%" }} />
        </Form.Item>

        <Form.Item label="Comments" name="comments">
          <Input placeholder="Comments" />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 6,
            span: 16,
          }}
          className="btn-row"
        >
          <Button
            icon={<PlusOutlined />}
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            Add Request
          </Button>
        </Form.Item>
      </Form>
    </React.Fragment>
  );
};

export default AddRequest;
