import React, { useState } from "react";
import { Button, message, Select, DatePicker, Space, Typography } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import moment from "moment";

const { RangePicker } = DatePicker;
const { Text } = Typography;

const ExcelExport = (props) => {
  const { data } = props;
  const role = localStorage.getItem("role");
  const [isLoading, setLoading] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [requestDateRange, setRequestDateRange] = useState([null, null]);
  const [pickupDateTimeRange, setPickupDateTimeRange] = useState([null, null]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);

  const uniqueStatuses = [...new Set(data.map((item) => item.status))];
  const uniqueSuppliers = [...new Set(data.map((item) => item.supplierName))];

  const handleExport = () => {
    setLoading(true);

    const filteredData = data
      .filter((item) => {
        const isStatusValid =
          selectedStatuses.length === 0 ||
          selectedStatuses.includes(item?.status) ||
          (selectedStatuses.includes("Confirmed") &&
            item?.status === "Cancelled" &&
            item.isCancellationChargeable);

        const isSupplierValid =
          selectedSuppliers.length === 0 ||
          selectedSuppliers.includes(item?.supplierName);

        const isPickupDateValid =
          !pickupDateTimeRange[0]?.$d ||
          !pickupDateTimeRange[1]?.$d ||
          moment(item?.pickupDateTime).isBetween(
            moment(pickupDateTimeRange[0]?.$d),
            moment(pickupDateTimeRange[1]?.$d).add(1, "day"),
            null,
            "[]"
          );

        const isRequestDateValid =
          !requestDateRange[0]?.$d ||
          !requestDateRange[1]?.$d ||
          moment(item?.requestDate).isBetween(
            moment(requestDateRange[0]?.$d),
            moment(requestDateRange[1]?.$d).add(1, "day"),
            null,
            "[]"
          );

        return (
          isStatusValid &&
          isSupplierValid &&
          isPickupDateValid &&
          isRequestDateValid
        );
      })
      .map((item) => {
        if (item.status === "Cancelled" && item.isCancellationChargeable) {
          return { ...item, status: "Cancellation Chargeable" };
        }
        return item;
      });

    let columns = [
      { key: "requestNumber", title: "Request Number" },
      { key: "requestDate", title: "Request Date" },
      { key: "pickupDateTime", title: "Pickup Date Time" },
      { key: "name", title: "Customer Name" },
      { key: "supplierName", title: "Supplier Name" },
      { key: "status", title: "Status" },
      { key: "state", title: "State" },
      { key: "city", title: "City" },
      { key: "pickupFrom", title: "Pickup From" },
      { key: "dropTo", title: "Drop To" },
      { key: "noOfTravellers", title: "No Of Travellers" },
      { key: "noOfBags", title: "No Of Bags" },
      { key: "vehicleType", title: "Vehicle Type" },
      { key: "distance", title: "Distance KM" },
      { key: "travellerName", title: "Traveller Name" },
      { key: "travellerPhone", title: "Traveller Phone" },
      { key: "quoteToClient", title: "Quote To Client" },
      { key: "quoteFromSupplier", title: "Quote From Supplier" },
      { key: "quoteMarkup", title: "Quote Markup" },
      { key: "quotationDate", title: "Quote Date" },
      { key: "bookingId", title: "LR Ticket Id" },
      { key: "bookingConfirmationNumber", title: "BRN" },
      { key: "confirmationDate", title: "Confirmation Date" },
      { key: "driverName", title: "Driver Name" },
      { key: "driverPhone", title: "Driver Phone" },
      { key: "vehicleDetails", title: "Vehicle Details" },
    ];

    if (role === "Customer") {
      columns = columns.filter(
        (x) =>
          x.key !== "name" &&
          x.key !== "supplierName" &&
          x.key !== "quoteMarkup" &&
          x.key !== "quoteFromSupplier"
      );
    }

    if (role === "Supplier") {
      columns = columns.filter(
        (x) =>
          x.key !== "name" &&
          x.key !== "supplierName" &&
          x.key !== "quoteMarkup" &&
          x.key !== "quoteToClient" &&
          x.key !== "bookingId"
      );
    }

    const wsData = [
      columns.map((col) => col.title),
      ...filteredData.map((item) =>
        columns.map((col) => {
          if (col.key === "requestDate" || col.key === "pickupDateTime") {
            return item[col.key]
              ? moment(item[col.key]).format("YYYY-MM-DD HH:mm:ss")
              : "";
          }
          return item[col.key];
        })
      ),
    ];

    const ws = XLSX.utils.aoa_to_sheet(wsData);
    const colWidth = columns.map((col) => ({ width: col.title.length + 5 }));
    ws["!cols"] = colWidth;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Exported Data");

    const currentDate = moment().format("YYYY-MM-DD");
    XLSX.writeFile(wb, `exported_data_${currentDate}.xlsx`);

    setLoading(false);
    message.success("Exported successfully!");
  };

  return (
    <React.Fragment>
      <Space direction="vertical" style={{ width: "100%" }}>
        {role === "Admin" && (
          <div className="mt-1">
            <Text strong>Supplier:</Text>
            <Select
              mode="multiple"
              placeholder="Select Supplier"
              value={selectedSuppliers}
              onChange={setSelectedSuppliers}
              style={{ width: "100%" }}
            >
              {uniqueSuppliers.map((supplier) => (
                <Select.Option key={supplier} value={supplier}>
                  {supplier}
                </Select.Option>
              ))}
            </Select>
          </div>
        )}

        <div>
          <Text strong>Status:</Text>
          <Select
            mode="multiple"
            placeholder="Select Status"
            value={selectedStatuses}
            onChange={setSelectedStatuses}
            style={{ width: "100%" }}
          >
            {uniqueStatuses.map((status) => (
              <Select.Option key={status} value={status}>
                {status}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div className="mt-1">
          <Text strong>Pickup Date:</Text>
          <RangePicker
            placeholder={["Pickup Date From", "Pickup Date To"]}
            value={pickupDateTimeRange}
            onChange={setPickupDateTimeRange}
            style={{ width: "100%" }}
          />
        </div>

        <div className="mt-1 mb-3">
          <Text strong>Request Date:</Text>
          <RangePicker
            placeholder={["Request Date From", "Request Date To"]}
            value={requestDateRange}
            onChange={setRequestDateRange}
            style={{ width: "100%" }}
          />
        </div>

        <Button
          icon={<ExportOutlined />}
          type="primary"
          loading={isLoading}
          onClick={handleExport}
        >
          Excel Export
        </Button>
      </Space>
    </React.Fragment>
  );
};

export default ExcelExport;
