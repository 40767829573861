import React, { useState, useEffect } from "react";
import fetchData from "../services/requester";
import dayjs from "dayjs";
import { Form, Input, Button, DatePicker, message } from "antd";
import { EditOutlined } from "@ant-design/icons";

import { ResDate } from "../components/formatter";

const RequestUpdate = (props) => {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const onFinish = (values) => {
    setLoading(true);
    let reqURL = "requests/update";
    let reqOBJ = values;

    reqOBJ.requestId = props?.requestId;
    reqOBJ.pickupDateTime = ResDate(values?.pickupDateTime);

    fetchData(reqURL, reqOBJ, (data) => {
      setLoading(false);

      if (data?.success) {
        message.success("Your Request updated successfully.");
        props.handleAdd();
      } else {
        message.error("Oops! Something went wrong.");
      }
    });
  };

  const disabledDate = (current) => {
    return current && current < dayjs().startOf("day");
  };

  const setDefaultData = () => {
    form.setFieldsValue({
      pickupDateTime: dayjs(props?.pickupDateTime) || "",
      flightDetails: props?.flightDetails || "",
      comments: props?.comments || "",
    });
  };

  useEffect(() => {
    setDefaultData();
  }, []);

  return (
    <React.Fragment>
      <Form
        name="request-add"
        form={form}
        {...layout}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Pickup Date & Time"
          name="pickupDateTime"
          rules={[
            {
              required: true,
              message: "Please select Pickup Date & Time!",
            },
          ]}
        >
          <DatePicker
            format={"DD/MM/YYYY HH:mm"}
            showTime={{ defaultValue: dayjs("00:00", "HH:mm") }}
            allowClear={false}
            disabledDate={disabledDate}
            style={{ minWidth: "50%" }}
          />
        </Form.Item>

        <Form.Item label="Flight Details" name="flightDetails">
          <Input placeholder="Flight Details" style={{ maxWidth: "50%" }} />
        </Form.Item>

        <Form.Item label="Comments" name="comments">
          <Input placeholder="Comments" />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 6,
            span: 16,
          }}
          className="btn-row"
        >
          <Button
            icon={<EditOutlined />}
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            Update Request
          </Button>
        </Form.Item>
      </Form>
    </React.Fragment>
  );
};

export default RequestUpdate;
