import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { Layout, Spin } from "antd";
import AppHeader from "./components/app-header";
import AppFooter from "./components/app-footer";
import SignIn from "./screens/signin";
import Signup from "./screens/signup";
import Dashboard from "./screens/dashboard";

function App() {
  const { Content } = Layout;
  const [isSignedIn, setIsSignedIn] = useState("");
  const [isAccessDenied, setIsAccessDenied] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const host = window.location.hostname;
  const isCustomerHost = host === "customer.travelebrium.com";

  const allowedIPRanges = [
    "136.226.232.0/23",
    "165.225.120.0/23",
    "167.103.2.0/23",
    "136.226.254.0/23",
    "165.225.206.0/23",
    "165.225.124.0/24",
    "136.226.250.0/23",
    "167.103.6.0/23",
    "136.226.252.0/23",
    "136.226.244.0/23",
    "136.226.242.0/23",
    "165.225.122.0/23",
    "14.140.184.29",
    "165.225.104.0/24",
    "167.103.18.0/23",
    "167.103.20.0/23",
    "167.103.22.0/23",
    "167.103.24.0/23",
    "167.103.26.0/23",
    "165.225.120.0/23",
    "167.103.6.0/23",
    "136.226.252.0/23",
    "136.226.244.0/23",
    "136.226.242.0/23",
    "165.225.122.0/23",
    "49.36.89.121",
  ];

  useEffect(() => {
    const isSignedIn = localStorage.getItem("token") ? true : false;
    setIsSignedIn(isSignedIn || "");

    if (!isCustomerHost) {
      setLoading(false);
      return;
    }

    const fetchIP = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        const userIP = data.ip;

        const isAllowed = allowedIPRanges.some((cidr) => {
          const [range, bits] = cidr.split("/");
          const ipBinary = userIP
            .split(".")
            .map((octet) => {
              const binary = parseInt(octet, 10).toString(2);
              return binary.padStart(8, "0");
            })
            .join("");

          const rangeBinary = range
            .split(".")
            .map((octet) => {
              const binary = parseInt(octet, 10).toString(2);
              return binary.padStart(8, "0");
            })
            .join("");

          const mask = (1 << (32 - bits)) - 1;
          return (
            (parseInt(ipBinary, 2) & ~mask) ===
            (parseInt(rangeBinary, 2) & ~mask)
          );
        });

        if (!isAllowed) {
          setIsAccessDenied(true);
        }
      } catch (error) {
        console.error("Error fetching IP address:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchIP();
  }, [navigate, location.pathname, isCustomerHost]);

  if (loading) {
    return (
      <div className="page-loader">
        <Spin size="large" />
      </div>
    );
  }

  if (isAccessDenied) {
    return (
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        Access Denied: Your IP is not allowed to access this site.
      </div>
    );
  }

  return (
    <Layout>
      <AppHeader key={isSignedIn} isSignedIn={isSignedIn} />
      <Layout>
        <Content>
          <Routes>
            <Route path="*" element={<Dashboard />} />
            <Route path="/signin" element={<SignIn />} />
            {isCustomerHost && <Route path="/signup" element={<Signup />} />}
          </Routes>
        </Content>
      </Layout>
      <AppFooter />
    </Layout>
  );
}

export default App;
