import React, { useState, useEffect } from "react";
import { Button, message } from "antd";

import fetchData from "../services/requester";

const ReminderDriverDetails = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [isButton, setIsButton] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  useEffect(() => {
    const buttonClicked = localStorage.getItem(
      `buttonClicked_${props?.requestId}`
    );
    if (buttonClicked === "true") {
      setIsButtonClicked(true);
    }

    checkCancellationWarning();
  }, [props?.pickupDateTime, props?.requestId]);

  const onFinish = () => {
    setLoading(true);
    let reqURL = "requests/reminder-driver-details";
    let reqOBJ = { requestId: props?.requestId };

    fetchData(reqURL, reqOBJ, (data) => {
      setLoading(false);
      setIsButtonClicked(true);
      localStorage.setItem(`buttonClicked_${props?.requestId}`, "true");

      if (data?.success) {
        message.success("Driver details reminder sent successfully.");
      } else {
        message.error(data || "Oops! Something went wrong.");
      }
    });
  };

  const checkCancellationWarning = () => {
    const pickupDateTime = new Date(props?.pickupDateTime);
    const currentTime = new Date();
    const timeDiff = pickupDateTime - currentTime;
    const hoursDiff = timeDiff / (1000 * 60 * 60);

    if (props?.status === "Confirmed") {
      setIsButton(hoursDiff <= 12 && hoursDiff >= 0);
    }
  };

  return (
    isButton &&
    !isButtonClicked && (
      <Button loading={isLoading} onClick={onFinish}>
        Request Details
      </Button>
    )
  );
};

export default ReminderDriverDetails;
